import PropTypes from 'prop-types';
import cs from 'classnames';
import React, { memo } from 'react';
import './hero-container.scss';
import heroBackground from '../../../images/heroImage.png';

const HeroContainer = (props) => {
  const { openModalHandler } = props;

  return (
    <div className={cs('on-hero')}>
      <div className="section" id="hero">
        <picture>
          <img alt="Red Oak Realty " src={heroBackground} />
        </picture>
        <div className="content">
          <div className="intro-box">
            <h1>Now, You Can Buy Before You Sell</h1>
            <h4>
              Powered by HomeLight Trade-In<sup>TM</sup>
            </h4>
            <p>
              A calmer, more certain alternative to real estate. Avoid the stress, risk, and hassle of buying and
              selling at the same time. HomeLight will buy your current home giving you the freedom to buy your new
              home.
            </p>
            <a className="button" onClick={() => openModalHandler(true)}>
              Start here
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

HeroContainer.propTypes = {
  openModalHandler: PropTypes.func,
};

HeroContainer.defaultProps = {
  openModalHandler: () => {},
};

export default memo(HeroContainer);
