import React, { memo } from 'react';
import PropTypes from 'prop-types';
import './header.scss';

const Header = (props) => {
  const { children } = props;
  return (
    <header id="header" className="on-header">
      <div className="header-container">{children}</div>
    </header>
  );
};

Header.propTypes = {
  children: PropTypes.node,
};

Header.defaultProps = { children: null };

export default memo(Header);
