import React, { memo } from 'react';
import cs from 'classnames';
import PropTypes from 'prop-types';
import { Menu } from '../menu';

const DesktopNav = (props) => {
  const { items, className, align } = props;

  return (
    <ul className={cs('nav-menu d-none d-sm-block', `align-${align}`)}>
      <Menu items={items} isMobile={false} className={className} type="desktop" />
    </ul>
  );
};

DesktopNav.propTypes = {
  items: PropTypes.array,
  className: PropTypes.string,
  align: PropTypes.string,
};

DesktopNav.defaultProps = {
  items: [],
  className: '',
  align: 'center',
};

export default memo(DesktopNav);
