import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Footer, SocialLinks } from '@components';
import './footer-container.scss';
import footerLogo from '../../../images/logo.png';
import footerPartnerLogo from '../../../images/footer-partner-logo.png';

import { socials } from '../../../data/constants/index';

const FooterContainer = (props) => {
  const { children } = props;

  return (
    <Footer {...props}>
      <div className="row">
        <div className="col-xs-12 col-sm-4 d-block d-md-none mb-2">
          <div>GET IN TOUCH</div>
          <a href="https://declanspring.redoakrealty.com/contact" className="connect-btn">
            Connect with me
          </a>
        </div>
        <div className="col-xs-12 col-md-4">
          <img src={footerLogo} className="footer-logo" alt="Footer Logo" />
          <ul className="footer-nav">
            <li>
              <a className="footer-link" href="https://declanspring.redoakrealty.com/">
                Home
              </a>
            </li>
            <li>
              <a className="footer-link" href="https://declanspring.redoakrealty.com/contact">
                Contact
              </a>
            </li>
            <li>
              <a className="footer-link" href="https://declanspring.redoakrealty.com/dmca-notica">
                DMCA Notice
              </a>
            </li>
            <li>
              <a className="footer-link" href="https://declanspring.redoakrealty.com/privacy-policy">
                Privacy Policy
              </a>
            </li>
            <li>
              <a className="footer-link" href="https://declanspring.redoakrealty.com/accessibility">
                Accessbiliy
              </a>
            </li>
          </ul>
          <SocialLinks
            className="footer-social"
            iconClassName="btn-social-icon"
            iconTypeClassName="fab"
            linkClassName="btn-social-link"
            textClassName="btn-social-text"
            showText={false}
            target="_blank"
            items={socials}
          />
        </div>
        <div className="col-xs-12 col-sm-4 d-none d-md-block">
          <div>GET IN TOUCH</div>
          <a href="https://declanspring.redoakrealty.com/contact" className="connect-btn">
            Connect with me
          </a>
        </div>
        <div className="col-xs-12 col-sm-4">
          <div>
            <a href="https://declanspring.redoakrealty.com/users/new" className="footer-link">
              Sign Up
            </a>
            <a href="https://declanspring.redoakrealty.com/login" className="footer-link">
              Login
            </a>
          </div>
          <img src={footerPartnerLogo} className="footer-partner-logo" alt="Green California Logo" />
        </div>
      </div>
      <div className="row copyright">© 2021 RED OAK REALTY | DECLAN SPRING DRE# 00619098</div>
    </Footer>
  );
};

FooterContainer.propTypes = {
  children: PropTypes.node,
};

FooterContainer.defaultProps = {
  children: <></>,
};

export default memo(FooterContainer);
