import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Header, SocialLinks, MobileNav, DesktopNav } from '@components';
import headerLogo from '../../../images/logo.png';

import './header-container.scss';

import { socials, menuItems } from '../../../data/constants';

const HeaderContainer = (props) => {
  const { children } = props;
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const toggleNav = () => {
    setMobileNavOpen(!mobileNavOpen);
  };

  return (
    <Header {...props}>
      <div className="row header-height d-none d-sm-flex">
        <div className="col-xs-2 col-md-3 d-none d-sm-block align-right logo-col header-height">
          <img src={headerLogo} className="header-logo-img" alt="Logo" />
        </div>
        <div className="col-xs-10 col-md-9 col-lg-7 d-none d-sm-block has-background menu-col header-height">
          <DesktopNav items={menuItems} className="on-desktop-menu" align="right" />
        </div>
        <div className="col-lg-2 d-none d-lg-block has-background social-col header-height">
          <div className="header-name">Declan Spring</div>
          <SocialLinks
            className="header-left"
            iconClassName="btn-social-icon"
            iconTypeClassName="fab"
            linkClassName="btn-social-link"
            textClassName="btn-social-text"
            showText={false}
            target="_blank"
            items={socials}
          />
        </div>
      </div>
      <div className="row header-height justify-between">
        <div className="mobile-menu-col d-flex d-sm-none">
          <FontAwesomeIcon
            icon="bars"
            className={cs('on-hamburger-menu', 'd-inline', 'd-sm-none')}
            onClick={toggleNav}
          />
        </div>
        <div className="mobile-logo-col d-flex d-sm-none align-center">
          <img src={headerLogo} className="mobile-logo" alt="Logo" />
        </div>
        <div className="mobile-search-col align-right d-flex d-sm-none">
          <FontAwesomeIcon
            icon="search"
            className={cs('on-hamburger-menu', 'd-inline', 'd-sm-none')}
            onClick={toggleNav}
          />
        </div>
      </div>

      <MobileNav items={menuItems} className="on-mobile-menu" isOpen={mobileNavOpen} toggleNav={toggleNav} />
      {children}
    </Header>
  );
};

HeaderContainer.propTypes = {
  children: PropTypes.node,
};

HeaderContainer.defaultProps = { children: null };

export default memo(HeaderContainer);
