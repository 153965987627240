export const menuItems = [
  {
    id: 1,
    htmlId: 'menu-homes',
    displayName: 'Homes For Sale', // TODO: I18n this
    url: 'https://declanspring.redoakrealty.com/searches/new',
    // ariaLabel: "", // TODO: a11y
    className: 'nav-menu-item',
    mobileClassName: 'mobile-nav-menu-item',
    children: [],
    displayOnMobile: true,
    displayOnDesktop: true,
  },
  {
    id: 2,
    htmlId: 'menu-explore',
    displayName: 'Explore the East Bay', // TODO: I18n this
    url: 'https://declanspring.redoakrealty.com/areas',
    // ariaLabel: "", // TODO: a11y
    className: 'nav-menu-item',
    mobileClassName: 'mobile-nav-menu-item',
    children: [],
    displayOnMobile: true,
    displayOnDesktop: true,
  },
  {
    id: 3,
    htmlId: 'menu-about',
    displayName: 'About Me', // TODO: I18n this
    url: 'https://declanspring.redoakrealty.com/profile',
    // ariaLabel: "", // TODO: a11y
    className: 'nav-menu-item',
    mobileClassName: 'mobile-nav-menu-item',
    children: [],
    displayOnMobile: true,
    displayOnDesktop: true,
  },
  {
    id: 4,
    htmlId: 'menu-testimonials  ',
    displayName: 'Testimonials', // TODO: I18n this
    url: 'https://declanspring.redoakrealty.com/testimonials',
    // ariaLabel: "", // TODO: a11y
    className: 'nav-menu-item',
    mobileClassName: 'mobile-nav-menu-item',
    children: [],
    displayOnMobile: true,
    displayOnDesktop: true,
  },
  {
    id: 5,
    htmlId: 'menu-contact',
    displayName: 'Contact', // TODO: I18n this
    url: 'https://declanspring.redoakrealty.com/contact',
    // ariaLabel: "", // TODO: a11y
    className: 'nav-menu-item',
    mobileClassName: 'mobile-nav-menu-item',
    children: [],
    displayOnMobile: true,
    displayOnDesktop: true,
  },
];
