import React, { memo } from 'react';
import PropTypes from 'prop-types';
import './footer.scss';

const Footer = (props) => {
  const { children } = props;
  return (
    <footer id="footer" className="on-footer">
      <div className="footer-container">{children}</div>
    </footer>
  );
};

Footer.propTypes = {
  children: PropTypes.node,
};

Footer.defaultProps = { children: null };

export default memo(Footer);
