export const socials = [
  {
    id: 0,
    linkClassName: 'btn-facebook',
    url: 'https://www.facebook.com/youreastbayrealtor/',
    iconClassName: 'facebook',
    text: 'Facebook',
  },
  {
    id: 1,
    linkClassName: 'btn-instagram',
    url: 'https://www.instagram.com/declanspring/',
    iconClassName: 'instagram',
    text: 'Instagram',
  },
  {
    id: 2,
    linkClassName: 'btn-yelp',
    url: 'https://www.yelp.com/biz/declan-spring-red-oak-realty-berkeley',
    iconClassName: 'yelp',
    text: 'Yelp',
  },
  {
    id: 3,
    linkClassName: 'btn-linkedin',
    url: 'https://www.linkedin.com/',
    iconClassName: 'linkedin',
    text: 'LinkedIn',
  },
];
