import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Header, SocialLinks, MobileNav, DesktopNav } from '@components';

import './header-container.scss';

import { socials, menuItems } from '@constants';

const HeaderContainer = (props) => {
  const { children } = props;
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const toggleNav = (e) => {
    setMobileNavOpen(!mobileNavOpen);
  };

  return (
    <Header {...props}>
      <FontAwesomeIcon icon="bars" className={cs('on-hamburger-menu', 'd-inline', 'd-md-none')} onClick={toggleNav} />
      <SocialLinks
        className="header-left"
        iconClassName="btn-social-icon"
        iconTypeClassName="fab"
        linkClassName="btn-social-link"
        textClassName="btn-social-text"
        showText={false}
        target="_blank"
        items={socials}
      />
      <MobileNav items={menuItems} className="on-mobile-menu" isOpen={mobileNavOpen} toggleNav={toggleNav} />
      <DesktopNav items={menuItems} className="on-desktop-menu" />
      {children}
    </Header>
  );
};

HeaderContainer.propTypes = {
  children: PropTypes.node,
};

HeaderContainer.defaultProps = { children: null };

export default memo(HeaderContainer);
