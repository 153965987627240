import cs from 'classnames';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import './hero-container.scss';

const HeroContainer = (props) => {
  const { openModalHandler } = props;

  return (
    <div className={cs('on-hero')}>
      <div className="section" id="hero">
        <picture>
          <source
            media="(max-width: 767px)"
            srcSet="https://d1xt9s86fx9r45.cloudfront.net/assets/hl-production/assets/v4/cash_close/trade-in-hero-mobile-1x-4c186886b76069ba9369672673c1c04e974444c68108feb1fe29b60a1161fbcd.webp 1x, https://d1xt9s86fx9r45.cloudfront.net/assets/hl-production/assets/v4/cash_close/trade-in-hero-mobile-2x-71a3e609c9d38c62e050422ef938ea2190c5ea9d7f0b81a7fb402fbdc70ca4ad.webp 2x"
            type="image/webp"
          />
          <source
            media="(min-width: 768px)"
            srcSet="https://d1xt9s86fx9r45.cloudfront.net/assets/hl-production/assets/v4/cash_close/trade-in-hero-desktop-1x-08d77101a84ef69e550aa4d5a0c5caf2c8dd29e5b1ea3d33cc6d1c89837e6b0e.webp 1x, https://d1xt9s86fx9r45.cloudfront.net/assets/hl-production/assets/v4/cash_close/trade-in-hero-desktop-2x-6133a9826b16d5d10e87d788b279a5b2253aa500121edc7856c0eb342e4f1a21.webp 2x"
            type="image/webp"
          />
          <source
            media="(max-width: 767px)"
            srcSet="https://d1xt9s86fx9r45.cloudfront.net/assets/hl-production/assets/v4/cash_close/trade-in-hero-mobile-1x-decd8c40b2d62a5e774cb83fefeb9af9ea69b00c05266ebc244b290013ca41fa.jpg 1x, https://d1xt9s86fx9r45.cloudfront.net/assets/hl-production/assets/v4/cash_close/trade-in-hero-mobile-2x-fa55db771e2cd3db9b944f41863b4d23d29a477e0039bb561eda92823a5812c1.jpg 2x"
            type="image/jpg"
          />
          <source
            media="(min-width: 768px)"
            srcSet="https://d1xt9s86fx9r45.cloudfront.net/assets/hl-production/assets/v4/cash_close/trade-in-hero-desktop-1x-96eeaa8f6f00349d65c8918e1af3f678d9d3dad416b1326ac1c261ce2fa642fe.jpg 1x, https://d1xt9s86fx9r45.cloudfront.net/assets/hl-production/assets/v4/cash_close/trade-in-hero-desktop-2x-d43badaa34974c508f3ba3c032fa2774af60e9e262d4ed15e001cb5d81bd9c9b.jpg 2x"
            type="image/jpg"
          />
          <img
            alt="Modern home"
            src="https://d1xt9s86fx9r45.cloudfront.net/assets/hl-production/assets/v4/cash_close/trade-in-hero-desktop-2x-d43badaa34974c508f3ba3c032fa2774af60e9e262d4ed15e001cb5d81bd9c9b.jpg"
          />
        </picture>
        <div className="content">
          <div className="intro-box">
            <h1>Become an All-Cash Buyer. Win Your Dream Home.</h1>
            <p>
              Powered by HomeLight Cash Offer<sup>TM</sup>
            </p>
            <p>
              HomeLight makes an all-cash offer on your behalf. That has real value. Cash offers are 3x more likely to
              win and can get you a 5% savings, on average, versus offers with a loan.*
            </p>
            <a className="button" onClick={() => openModalHandler(true)}>
              Start here
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

HeroContainer.propTypes = {
  openModalHandler: PropTypes.func,
};

HeroContainer.defaultProps = { openModalHandler: () => {} };

export default memo(HeroContainer);
